import * as i0 from '@angular/core';
import { Pipe, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["img"];
class ResizeHtmlImagesPipe {
  constructor() {
    // Inherited from CSS: $grid-breakpoints
    // is used to generate size attribute with collspans
    this.cssBreakPoints = {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    };
  }
  transform(htmlContent, width, height, imageSizes = [550, 800, 1200, 1600, 1920],
  // How many cols does the image span on what breakpoint?
  // For example: { md: 4, xl: 3 } is the same as bootstrap .col-md-4 .col-xl-3 classes
  sizes) {
    // if (!htmlContent) {
    //   return htmlContent;
    // }
    // let filters = ``;
    // if (width) {
    //   filters = `${filters}${this.separator(filters)}width=${width}`;
    // }
    // if (height) {
    //   filters = `${filters}${this.separator(filters)}height=${height}`;
    // }
    // const regex = /(<img [^>]*src=['"])((?:https?\:)?\/\/[^'"]+)([^>]*)(>)/gim;
    // let match;
    // while ((match = regex.exec(htmlContent))) {
    //   // console.log(match[2]);
    //   const src = `${match[2].replace(' ', '%20')}`;
    //   const newElement = `${match[1]}${src}?width=${width || imageSizes[imageSizes.length - 1]}${match[3]} srcset="${this.srcSetAttr(
    //     src,
    //     imageSizes,
    //     width || 0,
    //     height
    //   )}" sizes="${this.sizesAttr(sizes)}" loading="lazy" ${match[4]}`;
    //   htmlContent = htmlContent.replace(match[0], newElement);
    // }
    return htmlContent;
  }
  static {
    this.ɵfac = function ResizeHtmlImagesPipe_Factory(t) {
      return new (t || ResizeHtmlImagesPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "resizeHtmlImages",
      type: ResizeHtmlImagesPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeHtmlImagesPipe, [{
    type: Pipe,
    args: [{
      name: 'resizeHtmlImages'
    }]
  }], function () {
    return [];
  }, null);
})();
class ResizePipe {
  transform(imageUrl, width, height, anchor, mode, format, scale, other) {
    if (!imageUrl) {
      return imageUrl;
    }
    let val = `${imageUrl}`;
    if (width) {
      val = `${val}${this.separator(val)}width=${width}`;
    }
    if (height) {
      val = `${val}${this.separator(val)}height=${height}`;
    }
    if (anchor) {
      val = `${val}${this.separator(val)}anchor=${anchor}`;
    }
    if (mode) {
      val = `${val}${this.separator(val)}mode=${mode}`;
    }
    if (format) {
      val = `${val}${this.separator(val)}format=${format}`;
    }
    if (scale) {
      val = `${val}${this.separator(val)}scale=${scale}`;
    }
    if (other) {
      val = `${val}${this.separator(val)}${other}`;
    }
    return val;
  }
  separator(url) {
    return url.indexOf('?') >= 0 ? '&' : '?';
  }
  static {
    this.ɵfac = function ResizePipe_Factory(t) {
      return new (t || ResizePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "resize",
      type: ResizePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizePipe, [{
    type: Pipe,
    args: [{
      name: 'resize'
    }]
  }], null, null);
})();
class ImageComponent {
  constructor(cd, sanitizer) {
    this.cd = cd;
    this.sanitizer = sanitizer;
    this.cssClass = ''; //Uses CSS object-fit modus instead of url
    this.objectFitMode = true; //Uses CSS object-fit modus instead of url
    this.loaded = new EventEmitter();
    this.loading = 'lazy'; // Lazy loading
    // When both width and height are filled, this is used to calculate the aspect-ratio
    this.width = 0;
    this.height = 0;
    // Type of clipping when aspect ratio is given.
    this.mode = 'crop';
    //// Image file format
    this.format = 'webp';
    //// Scale
    this.scale = 'both';
    // Set what image sizes the srcset will contain (this will be used to pick from)
    this.imageSizes = [550, 800, 1200, 1600, 1920];
    // How many cols does the image span on what breakpoint?
    // For example: { md: 4, xl: 3 } is the same as bootstrap .col-md-4 .col-xl-3 classes
    this.sizes = {};
    // Overwrite the sizes attribute (to set it manually)
    this.customSizes = '';
    // Inherited from CSS: $grid-breakpoints
    // is used to generate size attribute with collspans
    this.cssBreakPoints = {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    };
    this.gcd = 0; // GCD is the highest number that evenly divides both numbers
    this.aspectRatio = 'auto'; // used for CSS, outputs an string as such: '16/9' or 'auto'
    this.resize = new ResizePipe();
  }
  ngOnInit() {
    this.gcd = this.aspect(this.height, this.width);
    this.aspectRatio = this.width && this.height ? `${this.width / this.gcd} / ${this.height / this.gcd}` : 'auto';
    // required alt text
    if (!this.image?.alt && !this.alt) {
      console.error(`Attribute 'alt' is required for image`, this.image);
    }
  }
  get getCSSvariables() {
    return this.sanitizer.bypassSecurityTrustStyle('--aspect-ratio:' + this.aspectRatio);
  }
  get sizesAttr() {
    // So youll be able to make your own size property build
    if (this.customSizes) {
      return this.customSizes;
    }
    if (!this.sizes) {
      return 'auto';
    }
    const sizeRules = [];
    // Sets for each container width an srcset (so for full width images)
    Object.keys(this.sizes).forEach(key => {
      const size = this.sizes[key];
      const breakpoint = this.cssBreakPoints[key];
      if (!size || !breakpoint) {
        return;
      }
      const width = 100 / 12 * size;
      sizeRules.push(`(min-width: ${breakpoint}px) ${width}vw`); // TODO calculate width with max width of container & vw?
    });

    return sizeRules.join(',').concat(', 100vw');
  }
  get srcSetAttr() {
    const srcSetRules = [];
    this.imageSizes.forEach(imgWidth => {
      srcSetRules.push(`${this.resize.transform(encodeURI(this.image.url || ''), imgWidth, this.getAspectRatioHeight(imgWidth), this.image.anchor || undefined, this.mode, this.format, this.scale, this.other)}  ${imgWidth}w` // TODO calculate width with max width of container & vw?
      );
    });

    return srcSetRules.join(',');
  }
  getAspectRatioHeight(size) {
    if (!this.height || !this.width) {
      return 0;
    }
    // ? What if only height is given ?
    const percentageDiff = (size - this.width) / this.width;
    const newHeight = Math.round((percentageDiff + 1) * this.height);
    return newHeight;
  }
  aspect(h, w) {
    return !w ? h : this.aspect(w, h % w);
  }
  static {
    this.ɵfac = function ImageComponent_Factory(t) {
      return new (t || ImageComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ImageComponent,
      selectors: [["fstr-image"]],
      viewQuery: function ImageComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.img = _t.first);
        }
      },
      inputs: {
        image: "image",
        cssClass: "cssClass",
        objectFitMode: "objectFitMode",
        alt: "alt",
        loading: "loading",
        width: "width",
        height: "height",
        mode: "mode",
        format: "format",
        scale: "scale",
        imageSizes: "imageSizes",
        sizes: "sizes",
        customSizes: "customSizes",
        other: "other"
      },
      outputs: {
        loaded: "loaded"
      },
      decls: 2,
      vars: 10,
      consts: [[3, "alt", "src", "srcset", "sizes", "ngClass", "load"], ["img", ""]],
      template: function ImageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "img", 0, 1);
          i0.ɵɵlistener("load", function ImageComponent_Template_img_load_0_listener() {
            return ctx.loaded.emit(true);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_2_0;
          i0.ɵɵstyleMap(ctx.getCSSvariables);
          i0.ɵɵclassProp("object-fit", ctx.cssClass);
          i0.ɵɵproperty("alt", (tmp_2_0 = (tmp_2_0 = ctx.image.alt) !== null && tmp_2_0 !== undefined ? tmp_2_0 : ctx.image.caption) !== null && tmp_2_0 !== undefined ? tmp_2_0 : ctx.alt)("src", ctx.image.url, i0.ɵɵsanitizeUrl)("srcset", ctx.srcSetAttr)("sizes", ctx.sizesAttr)("ngClass", ctx.objectFitMode ? "object-position--" + ctx.image.anchor : "" + " " + ctx.cssClass);
          i0.ɵɵattribute("loading", ctx.loading);
        }
      },
      dependencies: [i2.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageComponent, [{
    type: Component,
    args: [{
      selector: 'fstr-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<img\n  #img\n  [alt]=\"image.alt ?? image.caption ?? alt\"\n  [src]=\"image.url\"\n  [srcset]=\"srcSetAttr\"\n  [sizes]=\"sizesAttr\"\n  [attr.loading]=\"loading\"\n  (load)=\"loaded.emit(true)\"\n  [ngClass]=\"objectFitMode ? 'object-position--' + image.anchor : '' + ' ' + cssClass\"\n  [class.object-fit]=\"cssClass\"\n  [style]=\"getCSSvariables\"\n/>\n"
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i1.DomSanitizer
    }];
  }, {
    image: [{
      type: Input
    }],
    cssClass: [{
      type: Input
    }],
    objectFitMode: [{
      type: Input
    }],
    loaded: [{
      type: Output
    }],
    alt: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    imageSizes: [{
      type: Input
    }],
    sizes: [{
      type: Input
    }],
    customSizes: [{
      type: Input
    }],
    other: [{
      type: Input
    }],
    img: [{
      type: ViewChild,
      args: ['img']
    }]
  });
})();
class ImageModule {
  static {
    this.ɵfac = function ImageModule_Factory(t) {
      return new (t || ImageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ImageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageModule, [{
    type: NgModule,
    args: [{
      declarations: [ResizePipe, ResizeHtmlImagesPipe, ImageComponent],
      exports: [ResizePipe, ResizeHtmlImagesPipe, ImageComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ImageComponent, ImageModule, ResizeHtmlImagesPipe, ResizePipe };
